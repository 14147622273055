import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput
} from 'react-admin';

export const SiteEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled={true} title="ID"/>
            <BooleanInput source="active" label="Active"/>
            <TextInput source="name" label="Site Name"/>
            <TextInput source="description" label="Site Description"/>
            <TextInput source="code" label="Site Code"/>
            <BooleanInput source="ussd" label="USSD Enabled"/>
            <TextInput source="admin_username" label="Admin Username"/>
            {/*<TextInput source="admin_password"/>*/}
            <TextInput source="email"/>
            <ArrayInput source="phone_numbers">
                <SimpleFormIterator>
                    <TextInput label="Phone Number"/>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="scanners" label="Scanners"><SimpleFormIterator><TextInput source="id" disabled={true} label="id"/>
                <ReferenceInput source="user_id" reference="users" label="User ID"><SelectInput title="User ID" optionText="id"/></ReferenceInput>
                <BooleanInput source="permitted" label="Permitted to operate"/>
                <TextInput source="username" label="Username"/>
                <BooleanInput source="entry" label="Permitted to do entry scans"/>
                <BooleanInput source="exit" label="Permitted to do exit scans"/>
                {/*<TextInput source="password"/>*/}
            </SimpleFormIterator></ArrayInput>
            <ArrayInput source="extras" label="Extras"><SimpleFormIterator><TextInput source="id" disabled={true} label="id"/>
                <TextInput source="name" label="Name"/>
                <TextInput source="type" label="Type"/>
                <BooleanInput source="constant" label="Value is always the same"/>
                <TextInput source="description" label="Description"/></SimpleFormIterator></ArrayInput>
        </SimpleForm>
    </Edit>
);
import React from 'react';
import {List, Datagrid, TextField, EmailField, DateField} from 'react-admin';

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name.first" />
            <TextField source="name.middle" />
            <TextField source="name.last" />
            <TextField source="phone_number" />
            <EmailField source="email" />
            <TextField source="company" />
            <DateField source="dob" />
            <TextField source="car_license_plate" />
            <TextField source="id_number" />
            <TextField source="role" />
        </Datagrid>
    </List>
);
import React from "react";
import {Edit, SimpleForm, TextInput, DateTimeInput} from 'react-admin'

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled={true}/>
            <TextInput source="name.first" />
            <TextInput source="name.middle" />
            <TextInput source="name.last" />
            <TextInput source="phone_number" />
            <TextInput source="email" />
            <TextInput source="id_number" />
            <TextInput source="role" />
            <TextInput source="company" />
            <DateTimeInput source="dob" />
        </SimpleForm>
    </Edit>
);
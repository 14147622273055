import React from "react";
import {Create, SimpleForm, TextInput, DateTimeInput, PasswordInput} from 'react-admin'

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" disabled={true}/>
            <TextInput source="name.first" />
            <TextInput source="name.middle" />
            <TextInput source="name.last" />
            <TextInput source="id_number" />
            <TextInput source="email" />
            <TextInput source="phone_number" />
            <PasswordInput source="password" />
            <TextInput source="company" />
            <DateTimeInput source="dob"/>
        </SimpleForm>
    </Create>
);
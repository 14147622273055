import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    PasswordInput
} from 'react-admin';

export const SiteCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" disabled={true} title="ID"/>
            <TextInput source="name" label="Site Name"/>
            <TextInput source="description" label="Site Description"/>
            <TextInput source="code" label="Site Code"/>
            <BooleanInput source="ussd" label="USSD Enabled"/>
            <TextInput source="admin_username" label="Admin Username"/>
            <PasswordInput source="admin_password" label="Admin Password"/>
            <TextInput source="email"/>
            <ArrayInput source="phone_numbers">
                <SimpleFormIterator>
                    <TextInput label="Phone Number"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
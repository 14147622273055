import {fetchUtils} from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    if (property.includes(".")) {
        let properties = property.split(".")
        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            let result = (a[properties[0]][properties[1]] < b[properties[0]][properties[1]]) ? -1 : (a[properties[0]][properties[1]] > b[properties[0]][properties[1]]) ? 1 : 0;
            return result * sortOrder;
        }

    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

function filterList(params, data) {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    if (String(order).toUpperCase() === "ASC") {
        data.sort(dynamicSort(`${field}`))
    } else {
        data.sort(dynamicSort(`-${field}`))
    }
    return data.slice((page - 1) * perPage, page * perPage - 1);
}

export default {
    getList: (resource, params) => {

        const url = `${apiUrl}/${resource}`;

        return httpClient(url).then(({json}) => {
            console.log(json)
            let data = filterList(params, json);
            return {
                data: data,
                total: json.length,
            }
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json,
        })),

    getMany: (resource) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },

    getManyReference: (resource, params) => {
        const url = `${apiUrl}/${resource}`;

        return httpClient(url).then(({json}) => {

            return {
                data: filterList(params, json),
                total: json.length,
            }
        });
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => {
            return ({data: json})
        })
    },

    updateMany: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...params.data, id: json.id},
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json})),

    deleteMany: (resource, params) => {
        let ids = []
        params.ids.forEach(
            id => {
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                    body: JSON.stringify(params.data),
                }).then(({json}) => {
                    ids.push(json.id)
                });
            }
        )
        return Promise.resolve({data: ids})

    },
};
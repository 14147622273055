import React from 'react';
import './App.css';
import {Admin, Resource} from "react-admin";
import authProvider from './authProvider'
import dataProvider from './dataProvider';
import {UserList} from "./components/UserList";
import {UserEdit} from "./components/UserEdit";
import {UserCreate} from "./components/UserCreate";
import {SiteList} from "./components/SiteList";
import {SiteEdit} from "./components/SiteEdit";
import {SiteCreate} from "./components/SiteCreate";
import {CompanyList} from "./components/CompanyList";
import {CompanyEdit} from "./components/CompanyEdit";
import {CompanyCreate} from "./components/CompanyCreate";

const App = () =>
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate}/>
        <Resource name="sites" list={SiteList} edit={SiteEdit} create={SiteCreate}/>
        <Resource name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate}/>
    </Admin>


export default App;

import React from 'react';
import {List, Datagrid, TextField, EmailField, ReferenceField} from 'react-admin'
import {TextArrayField} from "./TextArrayField";

export const CompanyList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <EmailField source="email" />
            <TextArrayField source="phone_numbers" />
            <TextField source="admin_username" />
            <ReferenceField source="site_id" reference="sites"><TextField source="id" /></ReferenceField>
        </Datagrid>
    </List>
);
import React from "react";
import {ArrayInput, Edit, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput} from 'react-admin';

export const CompanyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled={true}/>
            <ReferenceInput source="site_id" reference="sites"><SelectInput optionText="id"/></ReferenceInput>
            <TextInput source="name"/>
            <TextInput source="description"/>
            <TextInput source="email"/>
            <ArrayInput source="phone_numbers">
                <SimpleFormIterator>
                    <TextInput/>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="admin_username"/>
            {/*<TextInput source="admin_password" />*/}
        </SimpleForm>
    </Edit>
);
import React from 'react';
import {List, Datagrid, TextField, EmailField, ArrayField, SingleFieldList, ChipField, BooleanField} from 'react-admin'
import {TextArrayField} from "./TextArrayField";



export const SiteList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="code"/>
            <TextField source="description"/>
            <BooleanField source="ussd"/>
            <BooleanField source="active"/>
            <EmailField source="email"/>
            <TextArrayField source="phone_numbers"/>
            <TextField source="admin_username"/>
            {/*<TextField source="admin_password"/>*/}
            <ArrayField source="scanners" ><
                SingleFieldList><ChipField source="id"/></SingleFieldList></ArrayField>
        </Datagrid>
    </List>
);